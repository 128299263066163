import React, { Fragment, useEffect, useRef, useState } from "react";
import API from "@unisearch/helpers/api";
import { useRouter } from "next/router";
import { objectToQueryString } from "@unisearch/helpers";
import { CircularProgress, Slider } from "@mui/material";
import { IoCloseSharp } from "react-icons/io5";
import { FiChevronDown } from "react-icons/fi";
import styles from "./common.module.scss";

export const CommonSearch = (props) => {
  let ref = useRef(null);
  let lref = useRef(null);
  let inputFocus = useRef(null);
  const router = useRouter();
  const { asPath, pathname } = useRouter();

  const [loading, setLoading] = useState(false);
  const [showExam, setShowExam] = useState(false);

  const [toggleAdvancedSearch, setToggleAdvancedSearch] = useState(false);
  const [courseLevel, setCourseLevel] = useState([]);
  const [courseSpecialization, setCourseSpecialization] = useState([]);
  const [courseExams, setCourseExams] = useState([]);

  //keyword
  const [selectedKeyItem, setSelectedKeyItem] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(true);

  //location
  const [disableLocation, setDisableLocation] = useState(true);
  const [locationsuggestions, setLocationsuggestions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [showLocationSuggestions, setShowLocationSuggestions] = useState(true);
  const [locationKey, setLocationKey] = useState([]);
  const [searchObj, setSearchObj] = useState({
    ref: "general",
    refq: "",
    co: [],
    ci: [],
    st: [],
    crlvl: [],
    sp: [],
    ex: [],
    // tfees: [0, 0],
    // duration: [0, 0],
    limit: 10,
    page: 1,
  });

  //when keyword handle
  const handleKeyword = (event) => {
    const { value } = event.target;
    getData(value);
  };

  //handle change Keyword
  const handleChangeKeyword = (event) => {
    const { value } = event.target;
    setLocationKey("");
    setDisableLocation(true);

    getData(value);
    setSearchObj((prevState) => ({
      ...prevState,
      ref: "general",
      refq: value,
      co: [],
      ci: [],
      st: [],
    }));

    setSelectedLocation([]);

    if (value) {
      setDisableLocation(false);
    }
  };

  //when press enter button on search field
  const handleKeyPress = (event, formHandler) => {
    if (event.key === "Enter") {
      formHandler(event);
    }
  };

  //handle location
  const handleLocations = async (event) => {
    const { value } = event.target;
    setLocationKey(value);
    if (value.length == 0) {
      setLocationsuggestions([]);
      setShowLocationSuggestions(false);
    } else {
      await API.post("/public/search/suggestion", {
        searchString: value,
        suggestionType: "location",
      })
        .then((response) => {
          setLocationsuggestions(response.data);
          setShowLocationSuggestions(true);
        })
        .catch((err) => {
          console.log(err);
        });
      setDisableLocation(false);
    }
  };

  //get suggestions
  const getData = async (key) => {
    setSearchObj((prevState) => ({
      ...prevState,
      co: "",
      ci: "",
      st: "",
    }));

    if (key.length == 0) {
      setSuggestions([]);
      setShowSuggestions(false);
    } else {
      await API.post("/public/search/suggestion", {
        searchString: key,
        suggestionType: "general",
      })
        .then((response) => {
          setSuggestions(response.data);
          setShowSuggestions(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //Filter Suggestion
  const FilterContent = () => {
    return suggestions.map((item, index) => {
      switch (item.type) {
        case "course":
          return (
            <li key={index} onClick={(e) => handleSelection(e, item)}>
              <strong>{item.name}</strong>
              <span>{item.type}</span>
            </li>
          );
          break;
        case "specialization":
          return (
            <li key={index} onClick={(e) => handleSelection(e, item)}>
              <strong>{item.name}</strong>
              <span>{item.type}</span>
            </li>
          );
          break;
        case "university":
          return (
            <li key={index} onClick={(e) => handleSelection(e, item)}>
              <strong>{item.name}</strong>
              <span>{item.type}</span>
            </li>
          );
      }
    });
  };

  //set and filter Location
  const FilterLocation = () => {
    return locationsuggestions.map((item, index) => {
      return (
        <li key={index} onClick={(e) => handleLocationSelection(e, item)}>
          <strong>{item.name}</strong>
          <span>{item.type}</span>
        </li>
      );
    });
  };

  //handle Location selection
  const handleLocationSelection = (e, obj) => {
    setSelectedLocation([...selectedLocation, obj]);
    setLocationKey("");
    switch (obj.type) {
      case "country":
        setSearchObj((prevState) => ({
          ...prevState,
          co: [...prevState.co, obj.slug],
        }));
        break;
      case "state":
        setSearchObj((prevState) => ({
          ...prevState,
          st: [...prevState.st, obj.slug],
        }));
        break;
      case "city":
        setSearchObj((prevState) => ({
          ...prevState,
          ci: [...prevState.ci, obj.slug],
        }));
        break;
    }
    setShowLocationSuggestions(false);
  };

  //when select keyword
  const handleSelection = (e, obj) => {
    setSelectedKeyItem(obj);
    switch (obj.type) {
      case "university":
        setSearchObj((prevState) => ({
          ...prevState,
          refq: obj.name.trim(),
          ref: "university",
        }));
        //set selected location
        filterUniversityOptions(obj);
        break;
      case "course":
        setSearchObj((prevState) => ({
          ...prevState,
          refq: obj.name.trim(),
          ref: "course",
        }));
        //active location
        filterCourseOptions(obj);
        break;
      case "specialization":
        setSearchObj((prevState) => ({
          ...prevState,
          refq: obj.name.trim(),
          ref: "general",
        }));
        break;
      default:
        setSearchObj((prevState) => ({
          ...prevState,
          key: null,
          keyType: null,
        }));
        setToggleAdvancedSearch(false);
    }

    setShowSuggestions(false);
  };

  //set and filter Location
  const filterCourseOptions = async (object) => {
    const { slug } = object;
    setDisableLocation(false);
  };

  //filtered University Options
  const filterUniversityOptions = async (object) => {
    const { slug } = object;
    //get locations form university
    await API.get(`/public/university/uni-country-state-city/${slug}`)
      .then((response) => {
        if (!response.error && response.data) {
          let data = response.data[0];
          setSelectedLocation([
            {
              name: data.countryName,
              slug: data.countrySlug,
              type: "country",
            },
            {
              name: data.stateName,
              slug: data.stateSlug,
              type: "state",
            },
          ]);

          //set state
          setSearchObj((prevState) => ({
            ...prevState,
            co: [data.countrySlug],
            st: [data.stateSlug],
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setDisableLocation(true);
  };

  //when click on serach button
  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if (searchObj.ref == "university") {
      window.location.replace(
        `${process.env.NEXT_PUBLIC_URL}/study/${selectedKeyItem.countrySlug}/university/${selectedKeyItem.slug}`
      );
      return true;
    } else {
      let query = objectToQueryString(searchObj);
      // router.push({
      //   pathname: "/search",
      //   query: query,
      // });

      window.location.replace(`${process.env.NEXT_PUBLIC_URL}/search?${query}`);

      // if (props.popupHandler) {
      //   props.popupHandler(false);
      // }

      return true;
    }

    // setLoading(false);
    // return true;
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (showSuggestions && ref.current && !ref.current.contains(e.target)) {
        setShowSuggestions(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showSuggestions]);

  //location sugession visibility
  useEffect(() => {
    const checkIfClickedOutsideLoc = (e) => {
      if (
        showLocationSuggestions &&
        lref.current &&
        !lref.current.contains(e.target)
      ) {
        setShowLocationSuggestions(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutsideLoc);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutsideLoc);
    };
  }, [showLocationSuggestions]);

  //Remover Location Item
  const removeItem = (index) => {
    const removeItem = selectedLocation[index];
    if (removeItem.type == "country") {
      let searchCountry = [...searchObj.co];
      let newSearchCountry = searchCountry.filter(
        (item) => item != removeItem.slug
      );
      setSearchObj((prevState) => ({
        ...prevState,
        co: newSearchCountry,
      }));
    }

    if (removeItem.type == "state") {
      let searchState = [...searchObj.st];
      let newSearchState = searchState.filter(
        (item) => item != removeItem.slug
      );
      setSearchObj((prevState) => ({
        ...prevState,
        st: newSearchState,
      }));
    }

    let newSelectedLocation = selectedLocation.filter(
      (item) => item.slug != removeItem.slug
    );
    setSelectedLocation(newSelectedLocation);
  };

  // useEffect(() => {
  //   inputFocus.current.focus();
  // }, []);

  //handle course Fees
  const handleFees = (e) => {
    console.log(e);
  };

  //handle course Fees
  const handleDuration = (e) => {};

  return (
    <Fragment>
      <form className={styles.search_form} onSubmit={submitHandler}>
        <div className={styles.search_form_input_wrapper}>
          <div ref={ref} className={styles.search_suggestion_wrapper}>
            <input
              // ref={inputFocus}
              type="text"
              name="search_keyword"
              className={styles.form_input}
              placeholder="Enter Course or University"
              autoComplete="off"
              onChange={handleChangeKeyword}
              onClick={handleKeyword}
              value={searchObj.refq ?? ""}
              onKeyPress={(e) => handleKeyPress(e, submitHandler)}
            />
            {showSuggestions && suggestions && suggestions.length > 0 && (
              <div className={styles.suggestions}>
                <ul>
                  <FilterContent />
                </ul>
              </div>
            )}
          </div>
          <div ref={lref} className={styles.location}>
            <ul className={styles.locationList}>
              {selectedLocation &&
                selectedLocation.map((loc, index) => {
                  return (
                    <li className={styles.listItem} key={`loc_${index}`}>
                      <span> {loc.name ?? ""} </span>
                      {!disableLocation && (
                        <button
                          className={styles.closeBtn}
                          type="button"
                          onClick={() => removeItem(index)}
                          disabled={disableLocation}
                        >
                          <IoCloseSharp />
                        </button>
                      )}
                    </li>
                  );
                })}
              {(selectedLocation.length == 0 ||
                (selectedLocation.length < 3 && !disableLocation) ||
                (selectedLocation.length > 0 && !disableLocation)) && (
                <li>
                  <input
                    type="text"
                    name="location"
                    className={styles.loc_input}
                    placeholder="Enter Location"
                    autoComplete="off"
                    onChange={handleLocations}
                    onClick={handleLocations}
                    value={locationKey ?? ""}
                    disabled={disableLocation}
                  />
                </li>
              )}
            </ul>
            {showLocationSuggestions &&
              locationsuggestions &&
              locationsuggestions.length > 0 && (
                <div className={styles.Locsuggestions}>
                  <ul>
                    <FilterLocation />
                  </ul>
                </div>
              )}
          </div>
        </div>
        <div className={styles.buttons}>
          <button
            type="button"
            onClick={submitHandler}
            className={`btn btn-secondary btn-bg ${
              searchObj.refq == "" ? styles.disable_btn : ""
            }`}
            disabled={loading}
          >
            {loading && <CircularProgress size={20} color="inherit" />} Search
          </button>
        </div>

        {toggleAdvancedSearch && (
          <div className={styles.advanced_search_container}>
            <div className={styles.advanced_search_select_container}>
              <div className={styles.select_wrapper}>
                <select
                  className={styles.form_input}
                  name="crlvl"
                  // onChange={handleChange}
                  value={searchObj.crlvl ?? []}
                >
                  <option value="">Course Level</option>
                  {courseLevel &&
                    courseLevel.map((item, index) => {
                      return (
                        <option
                          value={item.courselevel_slug}
                          key={`${item.courselevel_slug}_${index}`}
                        >
                          {item.courselevel_name}
                        </option>
                      );
                    })}
                </select>

                <div className={styles.select_icon}>
                  <FiChevronDown />
                </div>
              </div>

              <div
                className={styles.select_wrapper}
                style={{ marginRight: "3px" }}
              >
                <select
                  className={styles.form_input}
                  name="sp"
                  // onChange={handleChange}
                  value={searchObj.sp ?? ""}
                >
                  <option value="">Specialization</option>
                  {courseSpecialization &&
                    courseSpecialization.map((item, index) => {
                      return (
                        <option
                          value={item.specialization_slug}
                          key={`${item.specialization_slug}_${index}`}
                        >
                          {item.specialization_name}
                        </option>
                      );
                    })}
                </select>
                <div className={styles.select_icon}>
                  <FiChevronDown />
                </div>
              </div>

              <div
                className={styles.select_wrapper}
                style={{ marginLeft: "-2px" }}
              >
                <select
                  className={styles.form_input}
                  name="exam"
                  // onChange={handleChange}
                  value={searchObj.exam ?? ""}
                >
                  <option
                    value=""
                    key={Math.random()
                      .toString(36)
                      .substr(2, 9)}
                  >
                    Eligibility Exam
                  </option>
                  {courseExams &&
                    courseExams.map((item) => {
                      return (
                        <option
                          value={item.slug}
                          key={`${item.slug}_${Math.random()
                            .toString(36)
                            .substr(2, 9)}`}
                        >
                          {item.exam_name}
                        </option>
                      );
                    })}
                </select>
                <div className={styles.select_icon}>
                  <FiChevronDown />
                </div>
              </div>
            </div>
            <div className="advanced_search_range_wrapper">
              <div className={styles.advanced_search_range_container}>
                <div className={styles.range_wrapper}>
                  <p className={styles.range_title}>Fees & Expenses</p>
                  <div className={styles.range_slide}>
                    <Slider
                      value={[0, 7]}
                      onChange={handleFees}
                      valueLabelDisplay="auto"
                      disableSwap
                      min={0}
                      max={10}
                      step={1}
                    />
                  </div>
                  <div className={styles.range_text}>
                    <span>0</span>
                    <span>10</span>
                  </div>
                </div>
                <div className={styles.range_wrapper}>
                  <p className={styles.range_title}>Course Duration</p>
                  <div className={styles.range_slide}>
                    <Slider
                      value={[10, 20]}
                      onChange={handleDuration}
                      valueLabelDisplay="auto"
                      disableSwap
                      min={1}
                      max={36}
                      step={1}
                    />
                  </div>
                  <div className={styles.range_text}>
                    <span>0 Months</span>
                    <span>36 Months</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </Fragment>
  );
};

export default CommonSearch;
